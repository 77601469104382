<template>
  <v-text-field
    v-model="localValue"
    :append-icon="input.appendIcon"
    :class="['input']"
    dense
    :label="input.displayName"
    placeholder=" "
    :prefix="input.prefix"
    :prepend-inner-icon="input.prependIcon"
    :required="input.required"
    :rounded="input.rounded"
    :suffix="input.suffix"
    :to="input.to"
    :rules="input.rules"
    :disabled="disabled"
    :error-messages="errorMessages"
    height="1.3em"
  >
    {{ localValue }}
  </v-text-field>
</template>

<script>
export default {
  name: "TextField",

  props: {
    disabled: Boolean,
    errorMessages: String,
    input: {
      type: Object,
      required: false,
    },
  },

  data: () => ({}),

  computed: {
    localValue: {
      get() {
        return this.input.value
      },
      set(newValue) {
        this.$emit("update:value", newValue)
      },
    },
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: -30px;
}
</style>
